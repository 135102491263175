import xhr from "xhr";
import costumePayload from "./backpack/costume-payload";
import soundPayload from "./backpack/sound-payload";
import spritePayload from "./backpack/sprite-payload";
import codePayload from "./backpack/code-payload";
import { API } from "../services/api-service";

// Add a new property for the full thumbnail url, which includes the host.
// Also include a full body url for loading sprite zips
// TODO retreiving the images through storage would allow us to remove this.
const includeFullUrls = (item, host) =>
    Object.assign({}, item, {
        thumbnailUrl: `${host}/${item.thumbnail}`,
        bodyUrl: `${host}/${item.body}`,
    });

const getBackpackContents = ({ host, userId, token, limit, offset }) =>
    new Promise((resolve, reject) => {
        API.get(`/bagpack`, {
            userId: userId,
            limit: limit,
            offset: offset,
        })
            .then((res) => {
                if (res.data) {
                    return resolve(
                        res.data.response.map((item) =>
                            includeFullUrls(item, host)
                        )
                    );
                }
            })
            .catch((e) => {
                console.error(e);
                return reject(new Error(e));
            });
    });

const saveBackpackObject = ({
    host,
    userId,
    token,
    type, // Type of object being saved to the backpack
    mime, // Mime-type of the object being saved
    name, // User-facing name of the object being saved
    body, // Base64-encoded body of the object being saved
    thumbnail, // Base64-encoded JPEG thumbnail of the object being saved
}) =>
    new Promise((resolve, reject) => {
        API.post("/bagpack", {
            type,
            mime,
            name,
            body,
            thumbnail,
        })
            .then((res) => {
                return resolve(includeFullUrls(res.data, host));
            })
            .catch((e) => {
                console.error(e);
                return reject(new Error(e));
            });
    });

const deleteBackpackObject = ({ host, userId, token, id }) =>
    new Promise((resolve, reject) => {
        API.delete("/bagpack", {
            id,
        })
            .then((res) => {
                if (res.data) {
                    return resolve(res.data.response);
                }
            })
            .catch((e) => {
                console.error(e);
                return reject(new Error(e));
            });
    });

// Two types of backpack items are not retreivable through storage
// code, as json and sprite3 as arraybuffer zips.
const fetchAs = (responseType, uri) =>
    new Promise((resolve, reject) => {
        xhr(
            {
                uri,
                responseType,
            },
            (error, response) => {
                if (error || response.statusCode !== 200) {
                    return reject(new Error(response.status));
                }
                return resolve(response.body);
            }
        );
    });

// These two helpers allow easy fetching of backpack code and sprite zips
// Use the curried fetchAs here so the consumer does not worry about XHR responseTypes
const fetchCode = fetchAs.bind(null, "json");
const fetchSprite = fetchAs.bind(null, "arraybuffer");

export {
    getBackpackContents,
    saveBackpackObject,
    deleteBackpackObject,
    costumePayload,
    soundPayload,
    spritePayload,
    codePayload,
    fetchCode,
    fetchSprite,
};
